import { subDays, subMonths, subQuarters, subWeeks, subYears } from "date-fns";
import * as types from "_graphql-types/graphql";

export function applyPerformanceLag(
  performanceLag?: types.PerformanceLag | null,
  startDate: Date = new Date()
) {
  const subPeriods =
    performanceLag?.lagUnit.name === "Day"
      ? subDays
      : performanceLag?.lagUnit.name === "Week"
        ? subWeeks
        : performanceLag?.lagUnit.name === "Month"
          ? subMonths
          : performanceLag?.lagUnit.name === "Quarter"
            ? subQuarters
            : performanceLag?.lagUnit.name === "Year"
              ? subYears
              : undefined;

  const checkDate =
    subPeriods && performanceLag?.lag
      ? subPeriods(startDate, performanceLag.lag)
      : startDate;
  return checkDate;
}

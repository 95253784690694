import React, { useMemo, useState } from "react";
import { Modal, Select, DatePicker, InputNumber } from "antd";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { CLUSTER_REPORT, FETCH_PORTFOLIO_BALANCES } from "./graphql";
import { QUERY_ENTRIES } from "./Page/graphql";
import dayjs from "dayjs";
import { MAX_PERIODS } from "./Page/Portfolio/util";
import { saveAs } from "file-saver";

enum ReportType {
  cluster = "cluster",
}

export const ReportModalButton = ({
  portfolioId,
  date: defaultDate,
  lookthrough,
}: {
  portfolioId: number;
  date: Date;
  lookthrough: boolean;
}) => {
  const client = useApolloClient();
  const [reportType, setReportType] = useState<ReportType>(ReportType.cluster);
  const [date, setDate] = useState<Date>(defaultDate);
  const [calculationDate, setcalculationDate] = useState<Date>(defaultDate);
  const [excludeCash, setExcludeCash] = useState(false);
  const [timePeriod, setTimePeriod] = useState(60);
  const [strategies, setStrategies] = useState<string[]>([]);
  const [tagType, setTagType] = useState<"Client" | "Team" | "Open">("Client");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState<JSX.Element | null>(null);

  const cachedData = client.readQuery({
    query: QUERY_ENTRIES,
    variables: {
      date: dayjs(defaultDate).format("YYYY-MM-DD"),
      periods: MAX_PERIODS,
      lookthrough,
      portfolioId,
    },
  });

  const memoizedTags = useMemo(() => {
    if (!cachedData?.portfolio?.entries) return null;

    return cachedData.portfolio.entries.reduce(
      (acc, entry) => {
        if (entry.initBalance.tags) {
          acc.clientTags.add(entry.initBalance.tags.clientTag);
          if (entry.initBalance.tags.openTag) {
            acc.openTags.add(entry.initBalance.tags.openTag);
          }
          if (entry.initBalance.tags.teamTag) {
            acc.teamTags.add(entry.initBalance.tags.teamTag);
          }
        }
        return acc;
      },
      {
        clientTags: new Set<string>(),
        openTags: new Set<string>(),
        teamTags: new Set<string>(),
      }
    );
  }, [cachedData]);

  const handleOk = () => {
    ReportTypeMap[reportType].submit().then(url => {
      debugger;
      let reportPopup = window.open(url, "_blank");
      if (!reportPopup || reportPopup.closed) {
        setAlertMessage(
          <>
            Your popup blocker prevented the report from displaying, click this
            link to view your report <a href={url}>Report</a>
          </>
        );
        return;
      }

      setIsModalVisible(false);
    });
  };

  const [getClusterReport, { loading, data }] = useLazyQuery(CLUSTER_REPORT);

  const ClusterReportBody = () => {
    return (
      <div>
        <h2>Cluster Report</h2>
        <p>Date</p>
        <DatePicker
          defaultValue={dayjs(date)}
          onChange={date => {
            setDate(date?.toDate() ?? defaultDate);
          }}
          allowClear={false}
          format="MMM YYYY"
          style={{ width: "100%" }}
          picker="month"
        />
        <p>Calculation Date</p>
        <DatePicker
          defaultValue={dayjs(calculationDate)}
          onChange={date => {
            setcalculationDate(date?.toDate() ?? defaultDate);
          }}
          allowClear={false}
          format="MMM YYYY"
          style={{ width: "100%" }}
          picker="month"
        />

        <p>Strategy Type</p>
        <Select
          value={tagType}
          style={{ width: "100%" }}
          onChange={value => {
            setTagType(value as "Client" | "Team" | "Open");
            setStrategies([]);
          }}
        >
          {["Client", "Team", "Open"].map(tag => (
            <Select.Option key={tag} value={tag}>
              {tag}
            </Select.Option>
          ))}
        </Select>
        <p>Strategies:</p>
        <Select
          style={{ width: "100%" }}
          mode="multiple"
          value={strategies}
          onChange={value => {
            setStrategies([...value]);
          }}
        >
          {tagType === "Client" &&
            memoizedTags?.clientTags &&
            Array.from(memoizedTags.clientTags).map(tag => (
              <Select.Option key={tag} value={tag}>
                {tag}
              </Select.Option>
            ))}
          {tagType === "Team" &&
            memoizedTags?.teamTags &&
            Array.from(memoizedTags.teamTags).map(tag => (
              <Select.Option key={tag} value={tag}>
                {tag}
              </Select.Option>
            ))}
          {tagType === "Open" &&
            memoizedTags?.openTags &&
            Array.from(memoizedTags.openTags).map(tag => (
              <Select.Option key={tag} value={tag}>
                {tag}
              </Select.Option>
            ))}
        </Select>
        <p>Time Period (Months)</p>
        <InputNumber
          value={timePeriod}
          min={1}
          max={60}
          onChange={value => {
            setTimePeriod(value as number);
          }}
          style={{ width: "100%" }}
          step={1}
        />

        <p>Exclude Cash</p>
        <Select
          value={excludeCash ? "Yes" : "No"}
          style={{ width: "100%" }}
          onChange={value => {
            setExcludeCash(value === "Yes");
          }}
        >
          <Select.Option value="No">No</Select.Option>
          <Select.Option value="Yes">Yes</Select.Option>
        </Select>
      </div>
    );
  };

  //key is report type, value is the component to render
  const ReportTypeMap: {
    [k in keyof typeof ReportType]: {
      controls: React.FC;
      submit: () => Promise<string>;
    };
  } = {
    cluster: {
      controls: ClusterReportBody,
      submit: async () => {
        const res = (
          await getClusterReport({
            variables: {
              portfolioId,
              date: dayjs(date).format("YYYY-MM-DD"),
              strategies,
              calculationDate: dayjs(calculationDate).format("YYYY-MM-DD"),
              timePeriod,
              tag: tagType,
              excludeCash,
            },
          })
        )?.data?.clusterReport;
        if (!res) {
          throw new Error("Failed to generate report");
        }
        return res;
      },
    },
  };

  const CurrentControls = ReportTypeMap[reportType].controls;

  return (
    <>
      <button
        data-cy="report-modal-button"
        type="button"
        className="round-icon-btn round-icon-btn--36 round-icon-btn--transparent-white ml-15 mb-10 hidden-lg-down"
        onClick={() => setIsModalVisible(true)}
      >
        <i className="icon icon-print font-15" />
      </button>
      <Modal
        title="Report Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
      >
        <CurrentControls />
      </Modal>
    </>
  );
};

export default ReportModalButton;

import NavHeader from "Components/layout/NavHeader";
import classNames from "classnames";
import { Link } from "react-router-dom";
import ReportModalButton from "../ReportModal";

export default function AssetAllocationPageNavBar(props: {
  rcgFundId?: number;
  portfolioId?: number;
  date?: Date;
  periods?: number;
  lookthrough?: boolean;
}): JSX.Element | null {
  return (
    <NavHeader>
      <div className="invst-header">
        <div className="invst-header__col--left">
          <div className={classNames("invst-header__title-wrap")}>
            &emsp;
            <div className="invst-header__title">
              <div id="investment-name" className="invst-header__title-txt">
                Asset Allocation
              </div>
            </div>
          </div>
        </div>

        <div className="invst-header__col--right">
          {props.portfolioId &&
            props.date &&
            props.lookthrough !== undefined && (
              <ReportModalButton
                portfolioId={props.portfolioId}
                date={props.date}
                lookthrough={props.lookthrough}
              />
            )}
          <div className="text-right hidden-lg-down">
            {!!props.rcgFundId && (
              <Link
                to={`/investments/${props.rcgFundId}#portfolio`}
                style={{ color: "white" }}
              >
                Portfolio View
              </Link>
            )}
          </div>
        </div>
      </div>
    </NavHeader>
  );
}

import { DownloadOutlined } from "@ant-design/icons";
import { ApolloClient, useApolloClient } from "@apollo/client";
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Row,
  Select,
  Spin,
  Switch,
  Tabs,
} from "antd";
import dayjs from "dayjs";
import RiskVisualizations from "frontend/src/components/RiskVisualizations";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PortfolioCategoryEnum } from "src/graphql-types/graphql";
import { useDebouncedCallback } from "use-debounce";
import AssetAllocationPageNavBar from "./AssetAllocationPageNavbar";
import { QUERY_PORTFOLIO, QUERY_PORTFOLIOS } from "./graphql";
import { Portfolio } from "./Portfolio/Portfolio";

type Options = Parameters<typeof AutoComplete>[0]["options"];
type Option = NonNullable<Options>[number];

export function CategoryOption({
  label,
  loading,
}: {
  label: string;
  loading: boolean;
}) {
  return (
    <>
      {label} {loading && <Spin size="small" />}
    </>
  );
}

const Label = ({ children }: PropsWithChildren) => {
  return (
    <label style={{ display: "block", fontSize: "0.8em" }}>{children}</label>
  );
};

async function getPortfolios(
  apolloClient: ApolloClient<object>,
  category: PortfolioCategoryEnum,
  nameSearch: string
) {
  const result = await apolloClient.query({
    query: QUERY_PORTFOLIOS,
    variables: { nameSearch, category },
  });
  return result.data.portfolioList.items;
}

type Portfolios = Awaited<ReturnType<typeof getPortfolios>> | null;

function getOptions(label: string, portfolios: Portfolios): Option[] {
  // done loading and there are no portfolios
  if (portfolios?.length === 0) return [];
  return [
    {
      label: <CategoryOption label={label} loading={!portfolios} />,
      options:
        portfolios?.map(portfolio => ({
          label: portfolio.name,
          value: String(portfolio.id),
          portfolio,
        })) ?? [],
    },
  ];
}

export function PortfolioAutocomplete(props: {
  setPortfolioId: (id?: number) => void;
  portfolioName?: string;
}) {
  const apolloClient = useApolloClient();

  const [primaryPortfolios, setPrimaryPortfolios] = useState<Portfolios>([]);
  const [sharedPortfolios, setSharedPortfolios] = useState<Portfolios>([]);
  const [userPortfolios, setUserPortfolios] = useState<Portfolios>([]);
  const [value, setValue] = useState("");
  useEffect(() => {
    if (!props.portfolioName) return;
    setValue(props.portfolioName);
  }, [props.portfolioName]);

  const options = useMemo(
    () =>
      [
        getOptions("Primary Portfolios", primaryPortfolios),
        getOptions("Shared Portfolios", sharedPortfolios),
        getOptions("My Portfolios", userPortfolios),
      ].flat(),
    [primaryPortfolios, sharedPortfolios, userPortfolios]
  );

  const search = useDebouncedCallback(async (value: string) => {
    value = value.trim();

    if (!value) {
      setPrimaryPortfolios([]);
      setSharedPortfolios([]);
      setUserPortfolios([]);
      return;
    }

    setPrimaryPortfolios(null);
    setSharedPortfolios(null);
    setUserPortfolios(null);

    getPortfolios(apolloClient, PortfolioCategoryEnum.Primary, value).then(
      setPrimaryPortfolios
    );
    getPortfolios(apolloClient, PortfolioCategoryEnum.Shared, value).then(
      setSharedPortfolios
    );
    getPortfolios(apolloClient, PortfolioCategoryEnum.User, value).then(
      setUserPortfolios
    );
  }, 300);

  return (
    <AutoComplete
      data-cy="portfolio-search"
      placeholder="portfolio search"
      options={options}
      value={value}
      onChange={(value, option) => {
        console.log("changed", value, option);
        // Text input and option selection/clear are all mixed into one handler
        if (option && "value" in option) return;
        setValue(value);
      }}
      allowClear
      onClear={() => {
        setValue("");
        props.setPortfolioId();
      }}
      onSelect={(value, option) => {
        setValue(option.label);
        setPrimaryPortfolios([]);
        setSharedPortfolios([]);
        setUserPortfolios([]);
        props.setPortfolioId(Number(value));
        console.log("selected", value, option);
      }}
      onSearch={search}
    />
  );
}

async function getPortfolio(apolloClient: ApolloClient<object>, id: number) {
  const result = await apolloClient.query({
    query: QUERY_PORTFOLIO,
    variables: { id },
    fetchPolicy: "no-cache",
  });
  return result.data.portfolio ?? undefined;
}
type Portfolio = NonNullable<Awaited<ReturnType<typeof getPortfolio>>>;

export function AssetAllocationPage() {
  const { portfolioId } = useParams();

  const apolloClient = useApolloClient();
  const [portfolio, setPortfolio] = useState<Portfolio>();
  useEffect(() => {
    if (portfolioId) {
      const id = Number(portfolioId);
      if (typeof id === "number" && Number.isInteger(id)) {
        getPortfolio(apolloClient, id).then(setPortfolio);
        return;
      }
    }
    setPortfolio(undefined);
  }, [portfolioId]);

  const dateRange = useMemo(() => {
    if (!portfolio) return;
    const { start, end } = portfolio.dateRange;
    return {
      min: dayjs(`${start}T00:00:00`),
      max: dayjs(`${end}T00:00:00`),
    }; // local times
  }, [portfolio]);

  const [date, setDate] = useState<dayjs.Dayjs | null>(null);
  useEffect(() => {
    setLookthrough(false);
    if (
      !(date && dateRange && dateRange.min <= date && date <= dateRange.max)
    ) {
      setDate(dateRange?.max ?? null);
    }
  }, [dateRange]);

  const PERIOD_OPTIONS = [
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 6 },
    { value: 12 },
  ];
  const [periods, setPeriods] = useState(1);

  const GROUP_BY_OPTIONS = [
    { value: "firm", label: "Firm" },
    { value: "client", label: "Client" },
    { value: "team", label: "Team" },
    { value: "open", label: "Open" },
    { value: "guideline", label: "Guideline" },
  ] as const satisfies any[];
  const [groupBy, setGroupBy] =
    useState<(typeof GROUP_BY_OPTIONS)[number]["value"]>("team");

  const [lookthrough, setLookthrough] = useState(false);
  const [excelFlag, setExcelFlag] = useState(0);

  const tabItems = useMemo(() => {
    if (!portfolio || !date) return [];
    return [
      {
        key: "portfolio",
        label: "Portfolio",
        children: (
          <Portfolio
            portfolioId={portfolio.id}
            date={date}
            periods={periods}
            groupBy={groupBy}
            lookthrough={lookthrough}
            excelFlag={excelFlag}
          />
        ),
      },
      {
        key: "riskVisualization",
        label: "Risk Visualization",
        children: (
          <RiskVisualizations portfolioId={portfolio.id} heightOffset={230} />
        ),
      },
    ];
  }, [portfolio, date, periods, groupBy, lookthrough, excelFlag]);

  const navigate = useNavigate();

  return (
    <>
      <AssetAllocationPageNavBar
        rcgFundId={portfolio?.investment?.id}
        portfolioId={portfolio?.id}
        date={date?.toDate()}
        lookthrough={lookthrough}
        periods={periods}
      />
      <Row gutter={8}>
        <Col span={12}>
          <Label>Portfolio</Label>
          <PortfolioAutocomplete
            portfolioName={portfolio?.name}
            setPortfolioId={id => {
              navigate(id ? `/assetAllocation/${id}` : `/assetAllocation`);
            }}
          />
        </Col>
        {dateRange && (
          <Col span={3}>
            <Label>Date</Label>
            <DatePicker
              data-cy="date-picker"
              picker="month"
              allowClear={false}
              value={date}
              minDate={dateRange.min}
              maxDate={dateRange.max}
              format="MMM YYYY"
              onChange={setDate}
              style={{ width: "100%" }}
            />
          </Col>
        )}
        {date && (
          <>
            <Col span={2}>
              <Label>Periods</Label>
              <Select
                data-cy="periods-select"
                options={PERIOD_OPTIONS}
                value={periods}
                onSelect={setPeriods}
              />
            </Col>
            <Col span={3}>
              <Label>Group By</Label>
              <Select
                data-cy="group-by-select"
                options={GROUP_BY_OPTIONS}
                value={groupBy}
                onSelect={value => {
                  console.log("group by", value);
                  setGroupBy(value);
                }}
              />
            </Col>
            <Col span={2}>
              <Label>Lookthru</Label>
              <Switch
                data-cy="lookthrough-switch"
                disabled={!portfolio?.isPrimary}
                checked={lookthrough}
                onChange={setLookthrough}
              />
            </Col>
            <Col span={2}>
              <Label>Excel</Label>
              <Button
                data-cy="download-excel"
                title="Download Excel spreadsheet"
                icon={<DownloadOutlined />}
                onClick={() => setExcelFlag(Date.now())}
              />
            </Col>
          </>
        )}
      </Row>
      {portfolio && date && (
        <Tabs data-cy="asset-allocation-tabs" items={tabItems} />
      )}
    </>
  );
}
